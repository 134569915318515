import schttp from 'public/src/services/schttp'
class FingerPrint {
  OPTIONS = {
    excludes: {
      enumerateDevices: true,
    },
  }
  /**
   * localCachekey
   */
  CACHE_KEY = 'DEVICE_FINGERPRINT_V2'
  /**
   * use menory cache
   */
  fingerPrint = ''

  get() {
    return new Promise(resolve => {
      resolve()
      // if (window.requestIdleCallback) {
      //   window.requestIdleCallback(() => this.#generate({ resolve }))
      // } else {
      //   window.setTimeout(() => {
      //     this.#generate({ resolve })
      //   })
      // }
    })
  }

  async #generate({ resolve: getResolve }) {
    return getResolve('')
    // let value = this.#getFormCache()
    // if (value !== '') return getResolve({ value })
    // async function bffGetIpSer () {
    //   const ipResult = await schttp({ url: '/user-api/setting/get_ip_info', useBffApi: true })
    //   return ipResult?.info || {}
    // }
    // Promise.all([
    //   bffGetIpSer(),
    //   fingerprint2.getPromise(this.OPTIONS)
    // ]).then((result) => {
    //   let ipInfo = result[0] || {}, fingerInfo = result[1] || []
    //   fingerInfo.push({
    //     key: 'ip',
    //     value: ipInfo.ipHash
    //   })
    //   let fingerInfoValues = []
    //   let getValues = (value) => {
    //     if (!Array.isArray(value)) {
    //       fingerInfoValues.push(value)
    //     } else {
    //       value.forEach((valueItem) => {
    //         getValues(valueItem)
    //       })
    //     }
    //   }
    //   fingerInfo.forEach((component) => getValues(component.value))
    //   value = fingerprint2.x64hash128(fingerInfoValues.join(''), 31)

    //   this.fingerPrint = value
    //   localStorage.setItem(this.CACHE_KEY, JSON.stringify({
    //     value: value,
    //     expire: Date.now() + 86400000
    //   }))
    //   getResolve({ value })
    // }).catch(() => {
    //   getResolve('')
    // })
  }

  #getFormCache() {
    return ''
    // if (this.fingerPrint) return this.fingerPrint
    // let localCacheFingerPrint = ''
    // try {
    //   let localCacheFingerPrintInfo = JSON.parse(localStorage.getItem(this.CACHE_KEY)) || { value: '', expire: 0 }
    //   if (localCacheFingerPrintInfo.expire >= Date.now()) {
    //     localCacheFingerPrint = localCacheFingerPrintInfo.value
    //   }
    // } catch(e) {
    //   console.log(e)
    // }
    // return localCacheFingerPrint
  }
}

export default new FingerPrint()
